import React, { useEffect, useState } from "react";
import "./OverlaySlider.css";
import BtnSlider from "./BtnSlider";
import ReactPlayer from "react-player";
import { playIcon, videoPlayIcon } from "../../assets";

export default function OverlaySlider(props) {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isOverlayPlayerHovered, setIsOverlayPlayerHovered] = useState(false);

  useEffect(() => {
    setSlideIndex(props.selectedIndex);
  }, [props.selectedIndex]);

  const nextSlide = () => {
    if (slideIndex !== props.media.length) {
      moveDot(slideIndex + 1);
    } else if (slideIndex === props.media.length) {
      moveDot(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      moveDot(slideIndex - 1);
    } else if (slideIndex === 1) {
      moveDot(props.media.length);
    }
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };

  const convertSecondsToMinutesAndSeconds = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${minutes}:${formattedSeconds}`;
  };
  return (
    <div
      className="container-slider"
      // onMouseEnter={() => setIsOverlayPlayerHovered(true)}
      // onMouseLeave={() => setIsOverlayPlayerHovered(false)}
    >
      {props.media.map((obj, index) => {
        return (
          <div
            key={index}
            style={{ height: "100%" }}
            className={slideIndex === index + 1 ? "active-anim" : "slide"}
          >
            {obj.viewable_media_type == "VIDEO" ? (
              <div
                style={{
                  position: "relative",
                  marginTop: "43%",
                  paddingTop: "91%",
                }}
              >
                <ReactPlayer
                  style={{ position: "absolute", top: 0, left: 0 }}
                  controls={true}
                  light={obj.thumbnail_url}
                  url={obj.media_url}
                  playIcon={
                    <div className="video-duration-view">
                      <img className="new-play-icon" src={videoPlayIcon} />
                      <span>
                        {" "}
                        {obj.duration != null
                          ? convertSecondsToMinutesAndSeconds(obj.duration)
                          : ""}
                      </span>
                    </div>
                  }
                  width="100%"
                  height="100%"
                  playing={true}
                  onReady={() => {
                    // handleOnReadyState(i, obj.id);
                  }}
                  onPause={() => {
                    // handlePauseState(i, obj.id);
                  }}
                  onPlay={() => {
                    // handlePlayState(i, obj.id);
                  }}
                  onClickPreview={() => {}}
                  config={{
                    file: {
                      attributes: {
                        style: {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        },
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <div className="slider-media-image-div">
                <img
                  alt=""
                  className="slider-pc-body-image-new"
                  height="100%"
                  src={obj.media_url}
                />
              </div>
            )}
          </div>
        );
      })}
      <div>
        <div className="overlay_crousel_control">
          <BtnSlider moveSlide={nextSlide} direction={"next"} />
          {/* <div className="container-dots">
            {props.media.map((_, index) => (
              <span
                key={index}
                className={index + 1 === slideIndex ? "dot active" : "dot"}
              ></span>
            ))}
          </div> */}
          <BtnSlider moveSlide={prevSlide} direction={"prev"} />
        </div>
      </div>
    </div>
  );
}
