import React, { useState, useCallback, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { Modal, TextContainer } from "@shopify/polaris";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { Line } from "rc-progress";
import fileDownload from "js-file-download";

import "./PublicMediaPlayer.css"; // Import your CSS file for styling
import {
  playIcon,
  reelPostIcon,
  feedPostIcon,
  storyPostIcon,
  tiktokPostIcon,
  downloadIcon,
  muteIcon,
  unmuteIcon,
} from "../assets";

import { Box } from "@mui/material";
import { LegacyStack } from "@shopify/polaris";
import moment from "moment";
import OverlaySlider from "./widgets/OverlaySlider";
import axios from "axios";
import TopHeader from "../TopHeader";

const PublicMediaPlayer = ({}) => {
  const ref = useRef(null);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const shopId = urlParams.get("shop_id");
  const mediaId = urlParams.get("media_id");

  const [contentPost, setContentPost] = useState(null);
  const [brandName, setBrandName] = useState(null);

  const [isContentLoading, setIsContentLoading] = useState(true);
  const [openSlider, setOpenSlider] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const [sliderSelectedIndex, setSliderSelectedIndex] = useState(1);
  const [active, setActive] = useState(false);
  const [activeOverlayRemoveRight, setOverlayActiveRemoveRight] =
    useState(false);
  const [openAmbassadorModal, setOpenAmbassadorModal] = useState(false);
  const [openInviteAffiliateModal, setOpenInviteAffiliateModal] =
    useState(false);
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);

  const [addClick, setAddClick] = useState(false);
  const [isShowPopupMenu, setIsShowPopupMenu] = useState(false);
  const [isShowDropdownPopupMenu, setIsShowDropdownPopupMenu] = useState(false);
  const [isOverlayPlayerHovered, setIsOverlayPlayerHovered] = useState(false);
  const [postKey, setPostKey] = useState(-1);
  const [postKeyMenu, setPostKeyMenu] = useState(-1);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [videoProgress, setVideoProgress] = useState(0);
  const [isComponentCreated, setIsComponentCreated] = useState(false);

  const [duration, setDuration] = useState(0);
  const playerRef = useRef(null);

  const handleProgress = (progress) => {
    setVideoProgress(progress.played * 100);
  };

  // Sets the video duration once it's loaded
  const handleDuration = (duration) => {
    setDuration(duration);
  };

  // Handles click on the progress bar to seek video
  const handleBarClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const clickPositionX = e.clientX - rect.left;
    const clickRatio = clickPositionX / rect.width;
    setVideoProgress(clickRatio);
    playerRef.current.seekTo(clickRatio, "fraction");
  };

  const openNewMenu = (indexValue) => {
    setAddClick(!addClick);
    setPostKeyMenu(indexValue);
    setPostKey(-1);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const downloadMediaAPI = (media_id, content) => {
    content.media.map((obj, index) => {
      if (obj.viewable_media_type === "VIDEO") {
        downloadVideoPostHandler(
          index,
          `${content.creator?.username.replace(/[^a-zA-Z 0-9]+/g, "")}-UGC.mp4`,
          content
        );
      } else {
        downloadImagePostHandler(
          index,
          `${content.creator?.username.replace(/[^a-zA-Z 0-9]+/g, "")}-UGC.png`,
          content
        );
      }
    });
  };

  const downloadImagePostHandler = (media_index, filename, content) => {
    //----- File Downloading Code ----------
    axios
      .get(content.media[media_index].download_url + `?filename=${filename}`, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const downloadVideoPostHandler = (media_index, filename, content) => {
    axios
      .get(content.media[media_index].download_url + `?filename=${filename}`, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  useEffect(() => {
    if (contentPost == null && shopId && mediaId) {
      getMediaPublicLink(shopId, mediaId);
    } else {
      // if (
      //   contentPost?.post_type == "STORY" ||
      //   contentPost?.post_type == "VIDEO" ||
      //   (contentPost?.post_type == "PRIVATE" &&
      //     contentPost?.media_type == "VIDEO")
      // ) {
      //   setIsVideoPlaying(false);
      // } else {
      //   setIsVideoPlaying(true);
      // }

      setIsContentLoading(false);
      setVideoProgress(0);
    }
  }, [contentPost]);

  const getMediaPublicLink = (shop_id, media_id) => {
    axios
      .get(
        process.env.REACT_APP_API_URI +
          `v1/shop/${shop_id}/media/${media_id}/public`
      )
      .then((res) => {
        console.log("res: ", res);
        setContentPost(res.data.media[0]);
        setBrandName(res.data.brand_name);
      });
  };

  const handlePauseState = () => {
    console.log("handlePauseState: ", isVideoPlaying);
    var newPost = contentPost;
    newPost = {
      ...contentPost,
      is_video_playing: isVideoPlaying ? false : true,
    };
    setIsVideoPlaying(isVideoPlaying ? false : true);
    setContentPost(newPost);
  };

  const formatNumber = (number) => {
    if (number < 1000) {
      return number.toString(); // return the number as is if it's less than 1000
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + "K"; // convert to K for thousands
    } else if (number < 1000000000) {
      return (number / 1000000).toFixed(1) + "M"; // convert to M for millions
    } else {
      return (number / 1000000000).toFixed(1) + "B"; // convert to B for billions
    }
  };

  const checkPlatformOpenProfile = (content) => {
    if (content?.platform === "TIKTOK") {
      window.open(`https://www.tiktok.com/@${content?.creator?.username}`);
    } else {
      window.open(`https://www.instagram.com/${content?.creator?.username}`);
    }
  };

  const downloadButton = (contentPost) => {
    return (
      <div
        className="overlay_download-media-div"
        onClick={() => {
          downloadMediaAPI(contentPost.id, contentPost);
        }}
      >
        <img
          alt=""
          className="overlay_action-div-btn-right-icon center"
          src={downloadIcon}
        />
      </div>
    );
  };

  const openOriginalPost = (contentPost) => {
    if (contentPost.platform === "TIKTOK") {
      window.open(
        `https://www.tiktok.com/@${
          contentPost.creator?.username
        }/video/${contentPost.id.substring(3)}`
      );
    } else {
      window.open(contentPost.media[0]?.permalink);
    }
  };

  return contentPost != null ? (
    <div>
      {" "}
      <TopHeader brandName={brandName} />
      <div className="public-overlay-container">
        <div
          className={`public-overlay-player ${
            contentPost.media_type === "VIDEO" ? "overlay-video-post" : ""
          }`}
        >
          {contentPost.media_type == "VIDEO" ? (
            <div
              onClick={() => {
                handlePauseState();
              }}
            >
              <ReactPlayer
                ref={playerRef}
                style={{ position: "absolute", top: 0, left: 0 }}
                className="react-player"
                controls={false}
                muted={isMuted}
                playIcon={<img src={playIcon} />}
                url={contentPost?.media[0]?.media_url}
                width="100%"
                height="100%"
                pip={false}
                playing={isVideoPlaying}
                onReady={() => {}}
                onPause={() => {}}
                onPlay={() => {}}
                onProgress={handleProgress}
                onDuration={handleDuration}
                progressInterval={50}
                onClickPreview={() => {}}
                config={{
                  file: {
                    attributes: {
                      style: {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      },
                    },
                  },
                }}
              />
            </div>
          ) : contentPost.media_type == "IMAGE" ? (
            <div className="media-image-div">
              <img
                alt=""
                className="new-pc-body-image-new"
                src={contentPost?.media[0]?.media_url}
              />
            </div>
          ) : (
            <OverlaySlider
              media={contentPost.media}
              selectedIndex={sliderSelectedIndex}
            />
          )}

          <Box className="overlayplayer_top_gradiant">
            {/*  Top Gradiant */}
          </Box>

          {contentPost.media_type == "VIDEO" && !isVideoPlaying ? (
            <img className="play-icon" src={playIcon} height={50} width={50} />
          ) : null}

          {contentPost.show_usage_rights != false ? (
            <Box className="overlayplayer_bottom_gradiant"></Box>
          ) : null}

          <Box className="overlayplayer_top_loader">
            {/* Custom Progress Bar */}

            {contentPost.post_type != "STORY" &&
            contentPost.post_type !== "PRIVATE" ? (
              <span
                onClick={() => {
                  openOriginalPost(contentPost);
                }}
                className="open-original-post-div"
              >
                Open original post
              </span>
            ) : null}

            {contentPost.media_type === "VIDEO" && (
              <div className="loading-bar" onClick={handleBarClick}>
                <Line
                  percent={videoProgress}
                  strokeWidth={1}
                  strokeColor="#ffffff"
                  trailColor="#C4BDBD"
                />
              </div>
            )}
          </Box>

          <Box className="public_overlay_post_time__cpgc">
            {moment(contentPost.created_at).format("MMM DD, yy")}
          </Box>

          <Box className="overlay_post_video_con_ab__cpgc">
            <Box position="relative">
              <Box display="flex">
                <img
                  onClick={() => {
                    checkPlatformOpenProfile(contentPost);
                  }}
                  className="overlay_post-user-profile-img"
                  src={contentPost.creator?.display}
                />
                <Box ml={1} mt={-0.4}>
                  <span
                    onClick={() => {
                      checkPlatformOpenProfile(contentPost);
                    }}
                    className="overlay_pc-header-inside-uname"
                    style={{ fontSize: 11 }}
                  >
                    @{contentPost.creator?.username}
                  </span>

                  <Box mt={-0.5}>
                    <span className="overlay_pc-header-inside-followers">
                      {contentPost.creator?.followers > 1000
                        ? formatNumber(contentPost.creator?.followers) +
                          " followers"
                        : contentPost.creator?.followers != null &&
                          contentPost.creator?.followers != 0
                        ? `${contentPost.creator?.followers} followers`
                        : ""}{" "}
                    </span>
                  </Box>
                </Box>
              </Box>
              <Box className="overlay_post_icon_on_profile__cpgc">
                <img
                  src={
                    contentPost.platform === "TIKTOK"
                      ? tiktokPostIcon
                      : contentPost.post_type === "REELS" ||
                        contentPost.post_type === "IMAGE"
                      ? reelPostIcon
                      : contentPost.post_type === "STORY"
                      ? storyPostIcon
                      : feedPostIcon
                  }
                  className="overlay_post_type_icon center"
                />{" "}
              </Box>
            </Box>
          </Box>

          <Box className="overlay_download_content_btn__cpgc">
            <div className="overlay_action-block-inner-container">
              <div className="overlay_action-div-btn-right">
                <Box
                  className="overlay_display_pgc"
                  style={{
                    marginBottom:
                      contentPost.stats != null &&
                      (contentPost.stats.views != 0 ||
                        contentPost.stats.likes != 0 ||
                        contentPost.stats.shares != 0 ||
                        contentPost.stats.comments != 0)
                        ? 0
                        : 0,
                  }}
                >
                  <LegacyStack vertical spacing="baseTight">
                    {downloadButton(contentPost)}

                    <div
                      className="overlay_bottom_post_3dotmenu__cpgc"
                      style={{
                        backgroundColor:
                          contentPost.media_type == "VIDEO" ? null : "gray",
                      }}
                    >
                      <img
                        alt=""
                        onClick={() => {
                          if (contentPost.media_type == "VIDEO") {
                            toggleMute();
                          }
                        }}
                        src={isMuted ? unmuteIcon : muteIcon}
                        className="overlay_action-div-btn-right-icon center"
                      />{" "}
                    </div>
                  </LegacyStack>
                </Box>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </div>
  ) : (
    <h1>Loading..</h1>
  );
};

export default PublicMediaPlayer;
