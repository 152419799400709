import { refunnelLogo } from "./assets";
import axios from "axios";
import { Box } from "@mui/material";

const TopHeader = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#1F1F30",
        textAlign: "left",
        padding: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <a href="https://www.refunnel.com/" target="_blank" rel="noreferrer">
        <img src={refunnelLogo} height="35px" style={{ marginLeft: "20px" }} />
      </a>
      <Box className="header_center_text__col">
        This is a collection of UGC content created by {props.brandName}.
      </Box>
      <div>
        <a href="https://www.refunnel.com/" target="_blank" rel="noreferrer">
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              marginLeft: "5px",
              textDecoration: "underline",
            }}
          >
            Learn more about Refunnel.
          </span>
        </a>
      </div>
    </div>
  );
};

export default TopHeader;
